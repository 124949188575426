<template>
  <v-card
    width="150"
    height="140"
  >
    <v-col
      cols="1"
    >
      <v-sheet
        color="#00796B"
        class="left stripe-position my-2"
        width="8"
        height="90%"
      />
    </v-col>
    <v-card-text>
      <div
        class="font-weight-bold ml-2 mt-n2 mb-n2"
        style="font-size: 10px;"
      >
        General Questionnaire
      </div>
    </v-card-text>
    <v-card-actions
      class="mt-11"
    >
      <v-btn
        text
        rounded
        x-small
        color="primary"
        class="mr-2"
        @click="$emit('open-general-questionairre-models')"
      >
        open
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiFile, mdiPencil, mdiTrashCanOutline } from '@mdi/js'

export default {
  props: {
    caseData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        file: mdiFile,
        trash: mdiTrashCanOutline,
        edit: mdiPencil
      },
      navigation: {
        width: 450,
        borderSize: 6
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .stripe-position {
    position: absolute;
  }
  .left {
    position: absolute;
    left: 5px;
  }
  .updateBtn {
    position: absolute;
    right: 6px;
    top: 2px;
  }
</style>
